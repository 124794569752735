import Vue from 'vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
vSelect.props.components.default = () => ({
    Deselect: {
      render: createElement => createElement('span', { class: "ar-icon icon-close text-lg" }, ''),
    },
    OpenIndicator: {
      render: createElement => createElement('span', { class: "ar-icon icon-arrow-down text-lg" }, ''),
    },
  });
  
Vue.component('v-select', vSelect)