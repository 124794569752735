<template>
    <div class="loader">
        <div class="ring-loader"></div>
    </div>
</template>

<script>
/**
 * Loader
*/  
export default {
    name: "loader",
};
</script>

<style lang="scss">
.loader {
    .ring-loader{
        display: inline-block;
        width: 40px;
        height: 40px; 
    }
    .ring-loader:after {
        content: " ";
        display: block;
        width: 50px;
        height: 50px;
        margin: 8px;
        border-radius: 50%;
        border: 3px solid $primary-400;
        border-color: $primary-400 transparent $primary-400 transparent;
        animation: lds-dual-ring 0.8s linear infinite;
    }
    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

}
</style>
