<template>
    <div class="sidebar">
        <div class="d-flex w-100 flex-column">
            <div class="d-flex justify-content-center w-100">
                <img src="logo.svg" alt="logo" class="logo" />
            </div>

            <hr class="line" />

            <div class="d-flex w-100 align-items-center">
                <span class="ar-icon icon-account text-4xl"></span>
                <div class="d-flex flex-column ml-3">
                    <div class="font-medium text-md">
                        {{ username }}
                    </div>
                    <div class="text-xxs text-gray-300">
                        {{ email }}
                    </div>
                    <a class="c-link mt-1" @click="handleLogout"> Odhlásiť sa </a>
                </div>
            </div>

            <hr class="line" />

            <router-link to="/models" class="sidebar-link" @click.native="handleCloseMenu">
                Modely
            </router-link>
            <router-link to="/add-model" class="sidebar-link" @click.native="handleCloseMenu">
                Pridať model
            </router-link>
            <router-link to="/categories" class="sidebar-link" @click.native="handleCloseMenu">
                Kategórie
            </router-link>
            <router-link to="/users" class="sidebar-link" v-if="isAdmin" @click.native="handleCloseMenu">
                Používatelia
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "",
    components: {},
    computed: {
        ...mapState("auth", ["username", "email", "roles"]),
        isAdmin() {
            if(this.roles?.includes('Admin')) {
                return true;
            }
            return false;
        }
    },
    data() {
        return {};
    },
    methods: {
        async handleLogout() {
            const res = await this.$store.dispatch('auth/logout');
            if(res) {
                this.$router.push('/login')                
            }
        },
        handleCloseMenu() {
            this.$emit("onClose");
        },
    },
    mounted() {
    },
};
</script>

<style lang="scss">
.sidebar {
    height: 100%;
    max-height: 100vh;
    width: 100%;
    background: $dark-500;
    padding: 1.8rem;
    @media screen and (min-width: 1400px) {
        width: 330px;
    }  
    img.logo {
        height: 100px;
        width: auto;
    }
    .sidebar-link {
        text-decoration: none;
        color: #888C97;
        font-weight: 700;
        padding: 12px 0;
        transition: color 0.25s ease;
        &:hover {
            color: white;
        }
    }
}
</style>
