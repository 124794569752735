<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h1>Pridať model</h1>
            </div>
            <div class="col-12 mt-7">
                <form @submit="handleAddModel">
                    <div class="form-group mb-2">
                        <label for="title" class="input-label">Názov</label>
                        <input type="text" class="form-control custom-input" id="title" v-model="title" />
                    </div>
                    <div class="form-group mb-2">
                        <label class="input-label">Kategória</label>
                        <v-select label="name" :options="categories" v-model="category" class="custom-v-select"></v-select>
                    </div>
                    <!-- <div class="form-group mb-2">
                        <label for="desc" class="input-label">Popis</label>
                        <textarea class="form-control custom-input" id="desc" v-model="desc" rows="5" />
                    </div> -->

                    <div class="row mb-2 mt-3">
                        <div class="col-12 col-md-6">
                            <div class="d-block">
                                <div class="mb-1">3D model Android (.glb)</div>
                                <label for="file-input-android" class="model-input-label mt-1">
                                    <div class="d-flex align-items-center">
                                        <span class="ar-icon icon-android text-4xl text-primary"></span>
                                        <div class="ml-2">Vybrať model .glb</div>
                                    </div>
                                </label>
                                <input type="file" class="form-control-file" id="file-input-android" @change="processModelFileAndroid" accept=".glb" ref="androidModelInput"/>
                            </div>

                            <div class="d-inline-flex align-items-center justify-content-between bg-dark-300 p-3 rounded" v-if="modelFileAndroid">
                                <div class="pr-6">
                                    {{modelFileAndroid.name}}
                                </div>
                                <span class="ar-icon icon-close text-red-400 cursor-pointer" @click="removeAndroidModel"></span>
                            </div>
                        </div>

                        <div class="col-12 col-md-6 mt-3 mt-md-0">
                            <div class="d-block">
                                <div class="mb-1">3D model iOS (.usdz)</div>
                                <label for="file-input-ios" class="model-input-label mt-1">
                                    <div class="d-flex align-items-center">
                                        <span class="ar-icon icon-apple text-4xl text-primary"></span>
                                        <div class="ml-2">Vybrať model .usdz</div>
                                    </div>
                                </label>
                                <input type="file" class="form-control-file" id="file-input-ios" @change="processModelFileIos" accept=".usdz" ref="iosModelInput"/>
                            </div>

                            <div class="d-inline-flex align-items-center justify-content-between bg-dark-300 p-3 rounded" v-if="modelFileIos">
                                <div class="pr-6">
                                    {{modelFileIos.name}}
                                </div>
                                <span class="ar-icon icon-close text-red-400 cursor-pointer" @click="removeIosModel"></span>
                            </div>
                        </div>

                    </div>


                    <div v-if="modelAndroidPreview" class="my-6">
                        <div class="model-viewer">
                            <!-- <model-obj :src="modelPreview" @on-load="modelLoad" @on-error="modelError" :backgroundAlpha="0"  ref="modelViewer" :glOptions="{ preserveDrawingBuffer: true, alpha: true }"/> -->

                            <model-gltf :src="modelAndroidPreview" :backgroundAlpha="0"  ref="modelViewer" :glOptions="{ preserveDrawingBuffer: true, alpha: true }"/>

                        </div>
                        <button class="btn btn-secondary  mt-3" type="button" @click="setThumbFromModel">
                            Nastaviť ako náhľadový obrázok
                        </button>
                    </div>


                    <div>
                        <div class="mb-1">Náhľadový obrázok</div>
                        <label for="file-input-img" class="model-input-label mt-1">
                            <div class="d-flex align-items-center">
                                <span class="ar-icon icon-plus text-4xl"></span>
                                <div class="ml-2">Vybrať obrazok</div>
                            </div>
                        </label>
                        <input type="file" class="form-control-file" id="file-input-img" @change="processThumbnail" />

                        <div class="img-preview-thumb" v-if="thumbPreview">
                            <img :src="thumbPreview" />                    
                        </div>
                    </div>

                    <loadingButton class="mt-5" type="submit" :loading="modelSaving">Uložiť</loadingButton>
                </form>               
               
            </div>
        </div>
    </div>
</template>

<script>
import loadingButton from "@/components/loading-button";
import { resizeImages, uriToBlob, uriToFile } from "@/utils/image-utils";
import { ModelObj, ModelGltf } from 'vue-3d-model';

/**
 * Add model page
*/ 
export default {
    name: "add-model",
    components: {
        loadingButton,
        ModelObj,
        ModelGltf
    },
    data() {
        return {
            categories: [],
            //form data
            title: "",
            category: null,
            desc: "",
            thumbFile: null,
            
            modelFileAndroid: null,
            modelFileIos: null,

            modelSaving: false,
        };
    },
    computed: {
        thumbPreview() {
            if(this.thumbFile) {
                return URL.createObjectURL(this.thumbFile);
            }
            return;
        },
        modelAndroidPreview() {
            if(this.modelFileAndroid) {
                return URL.createObjectURL(this.modelFileAndroid);
            }
            return;
        }
    },
    methods: {
        resizeImages,
        uriToBlob,
        uriToFile,
        /**
         * Store new model
        */ 
        async handleAddModel(e) {
            e.preventDefault();

            if(!this.title || !this.category || !this.thumbFile || (!this.modelFileAndroid && !this.modelFileIos)) {
                this.$toasted.show("Musíte vyplniť všetky polia (3D model pre aspoň 1 platformu)", {type: "error", position: "top-center", fullWidth: true, duration: 3000})
                return;
            }

            this.modelSaving = true;

            let fd = new FormData();
            //let thumbBlob = this.uriToBlob(this.thumbFile);
            fd.append("Name", this.title);
            //fd.append("Description", this.desc);
            fd.append("CategoryId", this.category.id);
            fd.append("Thumbnail", this.thumbFile);

            if(this.modelFileAndroid) {
                fd.append("AndroidModel", this.modelFileAndroid);
            }
            if(this.modelFileIos) {
                fd.append("IosModel", this.modelFileIos);
            }

            const res = await this.ajaxPost(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/model`, fd, {
                "Content-Type": "multipart/form-data",
            });
            if(res.status === 200) {
                this.resetForm();
                this.$toasted.show("Model bol úspešne pridaný", {type: "success", position: "top-center", fullWidth: true, duration: 3000})
            } else {
                this.$toasted.show("Nepodarilo sa pridať model", {type: "error", position: "top-center", fullWidth: true, duration: 3000})
            }
            this.modelSaving = false;
        },
        processModelFileAndroid(e) { 
            this.modelFileAndroid = e.currentTarget.files[0];     
        },
        processModelFileIos(e) {          
            this.modelFileIos = e.currentTarget.files[0];
        },
        /**
         * Process thumbnail image
        */ 
        async processThumbnail(e) {
            let input = e.currentTarget;
            this.thumbFile = null;
            let images = await this.resizeImages(input.files);
            if (images?.length > 0) {
                this.thumbFile = images[0];
            }
        },
        /**
         * Reset form
        */ 
        resetForm() {
            this.title = "";
            this.category = null;
            this.desc = "";
            this.modelFileAndroid = null;
            this.modelFileIos = null;
            this.thumbFile = null;
        },
        /**
         * Load model categories
        */ 
        async getCategories() {
            const res = await this.ajaxGet(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/category/admin`);
            if(res?.data) {
                this.categories = res.data;
            }           
        },
        /**
         * Get screenshot from modelViewer and set it as thumbnail
        */
        async setThumbFromModel(e) {
            e.preventDefault();
            let {renderer}  = this.$refs.modelViewer;
            let imageData =  renderer.domElement.toDataURL("image/png", 1.0);
            let newImgFile = await this.uriToFile(imageData, '3d-model-thumb.png', 'image/png');
            this.thumbFile = newImgFile;
        },
        /**
         * Remove android model file
        */
        removeAndroidModel() {
            this.modelFileAndroid = null;
            this.$refs.androidModelInput.value = '';
        },
        /**
         * Remove ios model file
        */
        removeIosModel() {
            this.modelFileIos = null;
            this.$refs.iosModelInput.value = '';
        }
    },
    mounted() {
        this.getCategories();
    },
};
</script>

<style lang="scss">
#file-input-android,
#file-input-ios,
#file-input-img {
    display: none;
}
.model-input-label {
    background: $dark-300;
    border-radius: 5px;
    padding: 20px 40px;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
        background: $dark-200;
    }
}

.model-viewer {
    width: 300px;
    height: 300px;
    border: 5px solid $dark-300;
    @media screen and (min-width: 500px) {
        width: 450px;
        height: 450px;
    }
    @media screen and (min-width: 700px) {
        width: 600px;
        height: 600px;
    }
}
</style>
