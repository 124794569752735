import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

//auth
import login from "./views/auth/login";
import register from "./views/auth/register";
import resetPassword from "./views/auth/reset-password";

import models from "./views/models";
import addModel from "./views/add-model";
import categories from "./views/categories"
import users from "./views/users"

const routes = [
    {
        name: 'home',
        path: '/',
        component: login,
    },
    {
        name: 'login',
        path: '/login',
        component: login,
    },
    {
        name: 'register',
        path: '/register',
        component: register
    },
    {
        name: 'reset-password',
        path: '/reset-password',
        component: resetPassword
    },
    {
        name: 'add-model',
        path: '/add-model',
        component: addModel,
        meta: { auth: true }
    },
    {
        name: 'models',
        path: '/models',
        component: models,
        meta: { auth: true }
    },
    {
        name: 'categories',
        path: '/categories',
        component: categories,
        meta: { auth: true }
    },
    {
        name: 'users',
        path: '/users',
        component: users,
        meta: { auth: true }
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;