<template>
    <div class="container pt-8">
        <div class="row justify-content-center">
            <div class="col-5">
                <div class="login-container rounded-md p-9">
                    <div class="text-white text-center text-xl font-bold">
                        Registrácia
                    </div>

                    <form class="mt-5" @submit="handleReg">
                        <div class="form-group mb-2">
                            <label for="email" class="input-label">Email</label>
                            <input type="text" class="form-control custom-input" id="email" v-model="email" required/>							
                        </div>
                        <div class="form-group mb-2">
                            <label for="username" class="input-label">Meno</label>
                            <input type="text" class="form-control custom-input" id="username" v-model="username" required/>							
                        </div>
                        <div class="form-group mb-2">
                            <label for="pass" class="input-label">Heslo</label>
                            <input type="password" class="form-control custom-input" id="pass" v-model="pass" required/>							
                        </div>
                        <div class="form-group mb-2">
                            <label for="passConf" class="input-label">Potvrdenie hesla</label>
                            <input type="password" class="form-control custom-input" id="passConf" v-model="passConfirm" required/>							
                        </div>

                        <button type="submit" class="btn btn-full mt-5">
                            Registrovať
                        </button>
                    </form>

                    <div class="text-white text-center mt-6">
                        Máte už účet?
                        <router-link to="/login" class="c-link">
                            Prihlásiť sa
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * Register page - not used
 * New users are added via administration panel
*/ 
export default {
    name: 'register',
    data() {
        return {
            email: '',
            username: '',
            pass: '',
            passConfirm: '',
        }
    },
    methods: {
        async handleReg(e) {
            e.preventDefault();

            const res = await this.$store.dispatch('auth/register', {
                email: this.email,
                password: this.pass,
                username: this.username,
            }) 
            
            if(res === 0) {
                //success
                this.$router.push('/login');
                this.$toasted.show("Registrácia prebehla úspešne", {type: "success", position: "top-center", fullWidth: true, duration: 3000})
            } else if(res === 1) {
                //user exist
                this.$toasted.show("Zadaný email je už registrovaný", {type: "error", position: "top-center", fullWidth: true, duration: 3000})
            } else {
                this.$toasted.show("Registrácia nebola úspešná", {type: "error", position: "top-center", fullWidth: true, duration: 3000})
            }
            
        }
    }
}
</script>

<style lang="scss">
    .login-container {
        background: $dark-400;
    }
</style>