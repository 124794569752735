<template>
	<button :type="type ? type : 'button'" class="btn" @click="handleButtonClick">
		<!-- <slot></slot> -->
		<div class="d-flex align-items-center loading-btn-content">
            <slot></slot>
			<div class="loadingio-spinner-dual-ring-4ytdpotzirm ml-2" v-if="loading">
				<div class="ldio-tql3jnbr04m">
					<div></div>
					<div><div></div></div>
				</div>
			</div>
			<!-- {{ loadingMessage || 'Odosiela sa' }} -->
		</div>
	</button>
</template>

<script>
/**
 * Button with loader
 * Loader is displayed if prop 'loading' is true
*/  
export default {
	name: "loading-button",
	props: {
		loading: Boolean,
		loadingMessage: String,
        type: String
    },
    methods: {
        /**
         * Emit click event     
        */
        handleButtonClick() {
            this.$emit('buttonClick');
        }
    }
};
</script>

<style lang="scss" scoped>
@keyframes ldio-tql3jnbr04m {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
.loading-btn-content {
    min-height: 24px;
}
.ldio-tql3jnbr04m div {
	box-sizing: border-box !important;
}
.ldio-tql3jnbr04m > div {
	position: absolute;
	width: 81px;
	height: 81px;
	top: 9.5px;
	left: 9.5px;
	border-radius: 50%;
	border: 11px solid #000;
	border-color: #ffffff transparent #ffffff transparent;
	animation: ldio-tql3jnbr04m 1s linear infinite;
}
.ldio-tql3jnbr04m > div:nth-child(2) {
	border-color: transparent;
}
.ldio-tql3jnbr04m > div:nth-child(2) div {
	position: absolute;
	width: 100%;
	height: 100%;
	transform: rotate(45deg);
}
.ldio-tql3jnbr04m > div:nth-child(2) div:before,
.ldio-tql3jnbr04m > div:nth-child(2) div:after {
	content: "";
	display: block;
	position: absolute;
	width: 11px;
	height: 11px;
	top: -11px;
	left: 24px;
	background: #ffffff;
	border-radius: 50%;
	box-shadow: 0 70px 0 0 #ffffff;
}
.ldio-tql3jnbr04m > div:nth-child(2) div:after {
	left: -11px;
	top: 24px;
	box-shadow: 70px 0 0 0 #ffffff;
}
.loadingio-spinner-dual-ring-4ytdpotzirm {
	width: 24px;
	height: 24px;
	display: inline-block;
	overflow: hidden;
	background: none;
}
.ldio-tql3jnbr04m {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(0.24);
	backface-visibility: hidden;
	transform-origin: 0 0; /* see note above */
}
.ldio-tql3jnbr04m div {
	box-sizing: content-box;
}
/* generated by https://loading.io/ */
</style>
