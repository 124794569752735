import Vue from "vue";

const state = () => ({
    accessToken: "",
    refreshToken: "",
    userId: "",
    email: "",
    username: "",
    loggedIn: false,
    roles: []
});

const getters = {
    getLoggedIn: (state, getters, rootState) => {
        return state.loggedIn;
    },
    getAccessToken: (state, getters, rootState) => {
        return state.accessToken;
    },
};

const actions = {
    async loadLoggedUser({ commit, dispatch, state }, regData) {
        try {
            const res = await Vue.axios.get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/auth/check-logged-user`, {
                withCredentials: true,
            }); 
            if (res) {
                commit("setAccessToken", res.data.accessToken);
                commit("setUserId", res.data.id);
                commit("setEmail", res.data.email);
                commit("setUsername", res.data.username);
                commit("setLoggedIn", true);
                commit("setRoles", res.data.roles);
                return true;
            }
           
        } catch (err) {
            if (err.response?.status === 401 || err.response?.status === 500) {
                await dispatch('logout');
            }
            return false;
        }
    },
    async register({ commit, dispatch, state }, regData) {
        try {
            await Vue.axios.post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/auth/register`, regData);
            return 0;
        } catch (err) {
            if (err.response.status === 403) {
                //email exist
                return 1;
            } else {
                return 2;
            }
        }
    },
    async login({ commit, dispatch, state }, loginData) {
        try {
            const res = await Vue.axios.post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/auth`, loginData, {
                withCredentials: true,
            });
            if (res) {
                commit("setAccessToken", res.data.accessToken);
                commit("setRefreshToken", res.data.refreshToken);
                commit("setUserId", res.data.id);
                commit("setEmail", res.data.email);
                commit("setUsername", res.data.username);
                commit("setLoggedIn", true);
                commit("setRoles", res.data.roles);
                
                return {
                    state: true,
                    message: ''
                }
            }
        } catch (err) {
            if (err.response.status === 401) {
                return {
                    state: false,
                    message: 'Nesprávne prihlasovacie údaje'
                }
            } else {
                return {
                    state: false,
                    message: 'Prihlásenie nebolo úspešné, skúste to neskôr'
                }
            }
        }
    },
    async refreshAccessToken({ commit, dispatch, state }, regData) {
        try {
            const res = await Vue.axios.post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/auth/refresh-token`, {}, {
                withCredentials: true,
            });
            if(res.data && res.data.length > 0) {
                commit("setAccessToken", res.data);
                return true
            }
            return false
        } catch (err) {
            if (err.response.status === 401 || err.response.status === 500) {
                dispatch('logout');
            }
            return false
        }
    },
    async logout({ commit, dispatch, state }) {
        try {
            await Vue.axios.post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/auth/logout`, {}, {
                withCredentials: true,
            });
            
            commit("setAccessToken", '');
            commit("setRefreshToken", '');
            commit("setUserId", '');
            commit("setEmail", '');
            commit("setUsername", '');
            commit("setLoggedIn", false);
            commit("setRoles", []);

            return true;
          
        } catch {
            return false;
        }
    },

    async resetPassword({ commit, dispatch, state }, email) {
        try {
            await Vue.axios.post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/auth/reset-password?email=${email}`, {}, {
                withCredentials: true,
            });          
            return {
                state: true,
                message: 'Na zadaný email bol odoslaný link na resetovanie hesla',
            };          
        } catch(err) {
            if (err.response.status === 404) {
                return {
                    state: false,
                    message: 'Používateľ so zadaným emailom neexistuje'
                }
            }
            return {
                state: false,
                message: 'Nepodarilo sa resetovať heslo'
            }
        }
    },
    async changePassword({ commit, dispatch, state }, resetData) {
        try {
            await Vue.axios.post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/auth/change-password?password=${resetData.password}`, {}, {
                headers: {
                    Accept: "application/json",                   
                    Authorization: `Bearer ${resetData.token}`,                
                },
            });          
            return {
                state: true,
                message: 'Heslo bolo úspešne zmenené',
            };          
        } catch(err) {            
            return {
                state: false,
                message: 'Nepodarilo sa zmeniť heslo'
            }
        }
    },
};

const mutations = {
    setAccessToken(state, payload) {
        state.accessToken = payload;
        if(payload) {
            localStorage.setItem('access-token', payload);
        } else {
            localStorage.removeItem('access-token');
        }
    },
    setRefreshToken(state, payload) {
        state.refreshToken = payload;
    },
    setUserId(state, payload) {
        state.userId = payload;
    },
    setEmail(state, payload) {
        state.email = payload;
    },
    setUsername(state, payload) {
        state.username = payload;
    },
    setLoggedIn(state, payload) {
        state.loggedIn = payload;
    },
    setRoles(state, payload) {
        state.roles = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
