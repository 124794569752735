/**
 * Resize images
 * @function
 * @param {Array} images - Images to resize  
 * @returns {Array} Resized Images
*/
export const resizeImages = async function(images) {
    return await new Promise((resolve, reject) => {
        console.log("start resizing");
        if (images.length > 0) {
            let retImages = [];
            let count = images.length;
            let proccessedImages = 0;

            for (let i = 0; i < count; i++) {
                let reader = new FileReader();
                reader.onload = (event) => {
                    let image = new Image();
                    image.src = event.target.result;
                    image.onload = async function () {
                        let imgPreview;
                        let height = this.height;
                        let width = this.width;
                        let ratio = 0;
                        let maxWidth = 1000;
                        let maxHeight = 1000;

                        if (width > maxWidth) {
                            ratio = maxWidth / width;
                            height = height * ratio;
                            width = width * ratio;
                        }
                        if (height > maxHeight) {
                            ratio = maxHeight / height;
                            height = height * ratio;
                            width = width * ratio;
                        }

                        let canvas = document.createElement("canvas");
                        let context = canvas.getContext("2d");
                        canvas.width = width;
                        canvas.height = height;
                        context.drawImage(image, 0, 0, canvas.width, canvas.height);
                        let imgType = "";
                        let imgExt = "";
                        if(images[i]?.type === 'image/png') {
                            imgType = 'image/png';
                            imgExt = '.png';
                        } else {
                            imgType = 'image/jpeg';
                            imgExt = '.jpeg';
                        }
                        let newImgData = canvas.toDataURL(imgType, 1);
                        let newImgFile = await uriToFile(newImgData, `image-${i}${imgExt}`, imgType)
                        
                        retImages.push(newImgFile);
                        proccessedImages++;
                        if (proccessedImages === count) {
                            resolve(retImages);
                        }
                    };
                };
                reader.readAsDataURL(images[i]);
            }
        } else {
            resolve(null);
        }
    });
};

/**
 * Convert dataUri to blob
 * @function
 * @param {string} dataURI - DataURI
 * @returns {Blob} Blob
*/
export const uriToBlob = function(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
};

/**
 * Convert dataUri to file
 * @function
 * @param {string} uri - Uri
 * @param {string} filename - Name of file
 * @param {string} mimeType - File type
 * @returns {File} file
*/
export const uriToFile = function(uri, filename, mimeType) {
    return (fetch(uri)
            .then(function(res){return res.arrayBuffer();})
            .then(function(buf){return new File([buf], filename,{type:mimeType});})
        );
}

