<template>
    <div class="rounded py-4 px-4 py-sm-6 d-flex category-item align-items-center">
        <div class="d-flex flex-column flex-sm-row">
            <div class="mr-4">
                <span class="font-bold">Id:</span>
                {{ user.id }}
            </div>
            <div class="mr-4">
                <span class="font-bold">Meno:</span>
                {{ user.username }}
            </div>
            <div >
                <span class="font-bold">Email:</span>
                {{ user.email }}
            </div>
        </div>

        <div class="d-flex ml-auto">
            <div class="edit-button" @click="openEditPasswordModal">
                <span class="ar-icon icon-settings"></span>
            </div>
            <div class="remove-button ml-3" @click="openDeleteUserModal">
                <span class="ar-icon icon-close"></span>
            </div>
        </div>

        <modal @onClose="closeDeleteUserModal" v-if="deleteUserOpen">
            <div class="p-7">
                <h3>Naozaj chcete odstrániť tohto používateľa?</h3>
                <div class="mt-3">Odstránením tohto používateľa budú odstránené všetky modely a kategórie vytvorené používateľom!</div>
                <div class="d-flex justify-content-end align-items-center mt-5">
                    <button class="btn btn-secondary" type="button" @click="closeDeleteUserModal">Zrušiť</button>
                    <loadingButton class="btn ml-3" type="button" :loading="userDeleting" @buttonClick="deleteUser">Odstrániť</loadingButton>
                </div>
            </div>
        </modal>

        <modal @onClose="closeEditPasswordModal" v-if="editPasswordOpen">
            <div class="p-6 p-sm-8 p-lg-9">
                <h3>Zmena hesla</h3>
                <form class="mt-5" @submit="changePassword">
                    <div class="form-group mb-2">
                        <label for="password" class="input-label">Heslo</label>
                        <input type="password" class="form-control custom-input" id="password" v-model="password" required />
                    </div>
                    <div class="form-group mb-2">
                        <label for="passwordConfirm" class="input-label">Potvrdenie hesla</label>
                        <input type="password" class="form-control custom-input" id="passwordConfirm" v-model="passwordConfirm" required />
                    </div>

                    <div class="d-flex justify-content-end align-items-center flex-wrap mt-5">
                        <button class="btn btn-secondary mb-2" type="button" @click="closeEditPasswordModal">Zrušiť</button>
                        <loadingButton class="btn ml-3 mb-2" type="submit" :loading="passwordSaving">Zmeniť heslo</loadingButton>
                    </div>
                </form>
                
            </div>
        </modal>
    </div>
</template>

<script>
import modal from "@/components/modal";
import loadingButton from "@/components/loading-button";
import { mapState } from "vuex";

/**
 * User list item
*/
export default {
    name: "user-item",
    props: ["user"],
    components: {
        modal,
        loadingButton,
    },
    data() {
        return {
            deleteUserOpen: false,
            userDeleting: false,
            editPasswordOpen: false,
            passwordSaving: false,

            password: '',
            passwordConfirm: '',

        };
    },
    computed: {
        ...mapState(["auth"]),
        // canEdit() {
        //     return this.auth?.roles.includes('Admin') || (this.category?.userId === this.auth?.userId)
        // }
    },
    methods: {     
        /**
         * Open modal to delete user
        */ 
        openDeleteUserModal() {
            this.deleteUserOpen = true;
        },
        /**
         * Close modal to delete user
        */ 
        closeDeleteUserModal() {
            this.deleteUserOpen = false;
        },
        /**
         * Delete user
        */ 
        async deleteUser() {
            this.userDeleting = true;
            const res = await this.ajaxDelete(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/auth/user/${this.user?.id}`);
            if (res.status === 200) {
                this.$emit("onDelete");
            } else {
                this.$toasted.show("Nepodarilo sa odstrániť používateľa", { type: "error", position: "top-center", fullWidth: true, duration: 3000 });
            }
            this.userDeleting = false;
        },
        /**
         * Open modal to edit user password
        */ 
        openEditPasswordModal() {
            this.editPasswordOpen = true;
        },
        /**
         * Close modal to edit user password
        */ 
        closeEditPasswordModal() {
            this.editPasswordOpen = false;
        },
        /**
         * Change user password
        */ 
        async changePassword(e) {
            console.log(e)
            e.preventDefault();
            if(this.password !== this.passwordConfirm) {
                this.$toasted.show('Heslá sa musia zhodovať', { type: "error", position: "top-center", fullWidth: true, duration: 3000 });
                return;
            }
            this.passwordSaving = true;

            const res = await this.ajaxPost(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/auth/change-password?password=${this.password}&userId=${this.user.id}`, {});           
           
            this.passwordSaving = false;

            if(res?.status === 200) {
                this.$toasted.show('Heslo bolo úspešne zmenené', { type: "success", position: "top-center", fullWidth: true, duration: 3000 });
                this.password = '';
                this.passwordConfirm = '';
                this.closeEditPasswordModal();
            } else {
                this.$toasted.show('Nepodarilo sa zmeniť heslo', { type: "error", position: "top-center", fullWidth: true, duration: 3000 });
            }

        }
    },
    mounted() {},
};
</script>

<style lang="scss">
.user-item {
    background: $dark-300;
    transition: all 0.25s ease;
    &:hover {
        background: $dark-200;
    }
}

.remove-button {
    width: 40px;
    height: 40px;
    background: $red-400;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.25s ease;
    cursor: pointer;
    &:hover {
        background: $red-500;
    }
}

.edit-button {
    width: 40px;
    height: 40px;
    background: $blue-400;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.25s ease;
    cursor: pointer;
    &:hover {
        background: $blue-500;
    }
}
</style>
