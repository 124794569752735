import Vue from 'vue'


Vue.mixin({    
    methods:{
        /**
         * Get request
         * @function
         * @param {string} url - Request url        
         * @param {Object} params - Request parameters
         * @param {bool} repeat - Avoid calling request in loop (token refresh)
         * @returns {Object} 
        */
        async ajaxGet(url, params = {}, repeat = true){                 
            try {
                const res = await this.axios.get(url, {
                    params: params,
                    headers: {
                        Accept: "application/json",                   
                        Authorization: this.$store.getters['auth/getLoggedIn'] ? `Bearer ${this.$store.getters['auth/getAccessToken']}` : null,
                    },
                })
                return res;
            } catch(err) {
                if(err.response?.status === 401 && repeat) {
                    if(await this.$store.dispatch('auth/refreshAccessToken')) {
                        return await this.ajaxGet(url, params, false);
                    }
                }
                console.error("Request error.", err)
                return {
                    state: false,
                    status: err.response?.status
                };
            }        
        },
        /**
         * Post request
         * @function
         * @param {string} url - Request url
         * @param {Object} data - Request data
         * @param {bool} repeat - Avoid calling request in loop (token refresh)
         * @returns {Object} 
        */
        async ajaxPost(url, data, repeat = true){      
            try {
                const res = await this.axios.post(url, data, {
                    headers: {
                        Accept: "application/json",                   
                        Authorization: this.$store.getters['auth/getLoggedIn'] ? `Bearer ${this.$store.getters['auth/getAccessToken']}` : null,
                        // 'Content-Type': 'multipart/form-data'
                    },
                })               
                return res
            } catch(err) {
                if(err.response?.status === 401 && repeat) {                   
                    if(await this.$store.dispatch('auth/refreshAccessToken')) {
                        return await this.ajaxPost(url, data, false);                
                    }                   
                }
                console.error("Request error.", err)
                return {
                    state: false,
                    status: err.response?.status
                };
            }                         
        },
        /**
         * Put request
         * @function
         * @param {string} url - Request url
         * @param {Object} data - Request data
         * @param {bool} repeat - Avoid calling request in loop (token refresh)
         * @returns {Object} 
        */
        async ajaxPut(url, data, repeat = true){             
            try {
                const res = await this.axios.put(url, data, {
                    headers: {
                        Accept: "application/json",                   
                        Authorization: this.$store.getters['auth/getLoggedIn'] ? `Bearer ${this.$store.getters['auth/getAccessToken']}` : null,
                    },
                })               
                return res
            } catch(err) {
                if(err.response?.status === 401 && repeat) {
                    if(await this.$store.dispatch('auth/refreshAccessToken')) {
                        return await this.ajaxPut(url, data, false);
                    }
                }
                console.error("Request error.")
                return {
                    state: false,
                    status: err.response?.status
                };
            }                         
        },
        /**
         * Delete request
         * @function
         * @param {string} url - Request url       
         * @param {bool} repeat - Avoid calling request in loop (token refresh)
         * @returns {Object} 
        */
        async ajaxDelete(url, repeat = true){                 
            try {
                const res = await this.axios.delete(url, {                    
                    headers: {
                        Accept: "application/json",                   
                        Authorization: this.$store.getters['auth/getLoggedIn'] ? `Bearer ${this.$store.getters['auth/getAccessToken']}` : null,
                    },
                })
                return res
            } catch(err) {
                if(err.response?.status === 401 && repeat) {
                    if(await this.$store.dispatch('auth/refreshAccessToken')) {
                        return await this.ajaxDelete(url);
                    }
                }
                console.error("Request error.")
                return {
                    state: false,
                    status: err.response?.status
                };
            }        
        },
    }
})
