<template>
    <div>
        <div class="page-wrapper d-flex w-100">
            <div class="page-sidebar d-none d-xl-block" v-if="loggedIn">
                <sidebar/>
            </div>
            <div class="page-content" :class="[loggedIn && 'page-content-logged']">
                <router-view></router-view>
            </div>
        </div>

        <transition name="fade">
            <div class="page-loader d-flex align-items-center justify-content-center" v-if="initLoading">
                <loader/>
            </div>        
        </transition>

        <div class="mobile-menu-toggle" @click="toggleSidebar"  v-if="loggedIn">
            <span class="ar-icon" :class="[sidebarOpen ? 'icon-close text-3xl' : 'icon-menu text-4xl']"></span>
        </div>

        <transition name="fade-in-right">
            <div v-if="loggedIn && sidebarOpen" class="mobile-sidebar">
                <sidebar @onClose="toggleSidebar"/>
            </div>
        </transition>
    </div>
</template>

<script>

import loader from '@/components/loader'
import sidebar from '@/components/parts/sidebar'
import { mapState } from "vuex";

export default {
    name: "App",
    components: {
        sidebar,
        loader
    },
    computed: {
		...mapState([]),
        ...mapState('auth', ["loggedIn"]),	
	},
    watch: {
        $route(to, from) {
            if(to.name === 'login' && this.sidebarOpen) {
                this.sidebarOpen = false;
            }
        }
    },
    data() {
        return {
            initLoading: true,
            sidebarOpen: false,
        }
    },
    methods: {
        /**
         * Check if user is logged
        */
        async loadLoggedUser() {
            const res = await this.$store.dispatch('auth/loadLoggedUser');
            if(res) {
                this.$router.push('/models')
            } else {
                if(this.$route.meta?.auth) {
                    this.$router.push('/login')

                }
            }
            this.initLoading = false;
        },
        toggleSidebar() {
            this.sidebarOpen = !this.sidebarOpen
        }
    },
    mounted() {
        this.loadLoggedUser()
    }

    
};
</script>

<style lang="scss">
.page-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
}
.page-content {
    flex-grow: 1;    
    background: url('./assets/img/pattern.svg') $dark-500;
    background-size: 120px;
    background-repeat: repeat;
    min-height: 100vh;
    &.page-content-logged {
        background: $dark-400;
        @media screen and (max-width: 1399px) {
            padding-top: 50px;
            padding-bottom: 30px;
        }
        @media screen and (min-width: 1400px) {
            padding: 70px 70px 70px 400px;
        }        
    }
}
.page-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: $dark-500;
    z-index: 500;
}

.mobile-menu-toggle {
    position: fixed;
    top: 0;
    right: 0;
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $dark-500;
    z-index: 50;
    @media screen and (min-width: 1400px) {
        display: none;
    }  
}
.mobile-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 1400px) {
        display: none;
    } 
}
</style>
