<template>
    <div class="rounded py-4 px-4 py-sm-6 d-flex model-item align-items-center">
        <div class="d-flex flex-column w-100">
            <div class="d-flex w-100 align-items-center">
                <div class="model-thumb mr-5">
                    <img :src="model.thumbnail" />
                </div>
                <div class="d-flex flex-column ">
                    <div class="d-flex flex-column flex-sm-row">
                        <div class="mr-4">
                            <span class="font-bold">Id:</span>
                            {{ model.id }}
                        </div>
                        <div class="">
                            <span class="font-bold">Meno:</span>
                            {{ model.name }}
                        </div>
                    </div>
                    <div class="w-100 mt-2 d-none d-sm-flex align-items-center">
                        <div class="mr-3 bg-primary py-1 px-2 rounded d-flex align-items-center" v-if="model.androidModelKey">
                            <span class="ar-icon icon-android mr-2"></span> Android
                        </div>
                        <div class="bg-primary py-1 px-2 rounded d-flex align-items-center" v-if="model.iosdModelKey">
                            <span class="ar-icon icon-apple mr-2"></span> iOS
                        </div>
                    </div>
                </div>
                <div class="remove-button ml-auto d-none d-sm-flex" @click="openDeleteModelModal" v-if="canEdit">
                    <span class="ar-icon icon-close"></span>
                </div>
            </div>
            <div class="d-flex d-sm-none w-100 mt-2 align-items-center">
                <div class="mr-3 bg-primary py-1 px-2 rounded d-flex align-items-center" v-if="model.androidModelKey">
                    <span class="ar-icon icon-android mr-2"></span> Android
                </div>
                <div class="bg-primary py-1 px-2 rounded d-flex align-items-center" v-if="model.iosdModelKey">
                    <span class="ar-icon icon-apple mr-2"></span> iOS
                </div>
                <div class="remove-button ml-auto" @click="openDeleteModelModal" v-if="canEdit">
                    <span class="ar-icon icon-close"></span>
                </div>
            </div>
        </div>       
       

        <modal @onClose="closeDeleteModelModal" v-if="deleteModelOpen">
            <div class="p-7">
                <h3>Naozaj chcete odstrániť tento model?</h3>                
                <div class="d-flex justify-content-end align-items-center mt-5">
                    <button class="btn btn-secondary" type="button" @click="closeDeleteModelModal">
                        Zrušiť
                    </button>
                    <loadingButton class="btn ml-3" type="button" :loading="modelDeleting" @buttonClick="deleteModel">Odstrániť</loadingButton>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import modal from "@/components/modal";
import loadingButton from "@/components/loading-button";
import { mapState } from 'vuex'

/**
 * Model list item
*/
export default {
    name: "model-item",
    props: ["model"],
    components: {
        modal,
        loadingButton
    },
    data() {
        return {
            deleteModelOpen: false,
            modelDeleting: false,
        };
    },
    computed: {
        ...mapState(['auth']),
        canEdit() {
            return this.auth?.roles.includes('Admin') || (this.model?.userId === this.auth?.userId)
        }
    },
    methods: {
        /**
         * Open modal to delete model
        */
        openDeleteModelModal() {
            this.deleteModelOpen = true;
        },
        /**
         * Close modal to delete model
        */
        closeDeleteModelModal() {
            this.deleteModelOpen = false;
        },
        /**
         * Delete model
        */
        async deleteModel() {
            this.modelDeleting = true;
            const res = await this.ajaxDelete(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/model/${this.model?.id}`);
            if (res.status === 200) {
                this.$emit("onDelete");
            } else {
                this.$toasted.show("Nepodarilo sa odstrániť model", { type: "error", position: "top-center", fullWidth: true, duration: 3000 });
            }
            this.modelDeleting = false;
        }
    },
    mounted() {},
};
</script>

<style lang="scss">
.model-item {
    background: $dark-300;
    transition: all 0.25s ease;
    &:hover {
        background: $dark-200;
    }
    .model-thumb {
        width: 80px;
        height: 80px;
        position: relative;
        @media screen and (min-width: 768px) {
             width: 120px;
            height: 120px;
        }
        img {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            -o-object-fit: contain;
            object-fit: contain;
        }
    }
}
    .platform-label {
        
    }
</style>
