<template>
    <div class="container pt-8">
        <div class="row justify-content-center">
            <div class="col-12 col-sm-10 col-md-8 col-xl-5">

                <div class="d-flex justify-content-center w-100 mb-8">
                    <img src="logo.svg" alt="logo" class="login-logo" />
                </div>

                <div class="login-container rounded-md p-7 p-sm-8 p-lg-9">
                    <div class="text-white text-center text-xl font-bold">Prihlásenie</div>

                    <form class="mt-5" @submit="handleLogin">
                        <div class="form-group mb-2">
                            <label for="email" class="input-label">Email</label>
                            <input type="text" class="form-control custom-input" id="email" v-model="email" required />
                        </div>
                        <div class="form-group mb-2">
                            <label for="pass" class="input-label">Heslo</label>
                            <input type="password" class="form-control custom-input" id="pass" v-model="pass" required />
                        </div>

                        <loadingButton class="mt-5 btn-full" type="submit" :loading="loginLoading">Prihlásiť</loadingButton>
                    </form>

                    <div class="d-flex w-100 justify-content-center mt-5">
                        <div class="c-link" @click="openForgotPassModal">Zabudol som heslo</div>
                    </div>
                </div>
            </div>
        </div>

        <modal @onClose="closeForgotPassModal" v-if="forgotPassModalOpen">
            <div class="p-6 p-sm-8 p-lg-9">
                <h3>Zabudol som heslo</h3>
                <div class="mt-3 ">
                    Zadajte email pre ktorý chcete resetovať heslo
                </div>

                <form @submit="hadleResetPass">
                    <div class="form-group mt-3">
                        <label for="forgot-email" class="input-label">Email</label>
                        <input type="email" class="form-control custom-input" id="forgot-email" v-model="forgotEmail" required />
                    </div>

                    <div class="d-flex justify-content-end align-items-center flex-wrap mt-5">
                        <button class="btn btn-secondary mb-2" @click="closeForgotPassModal">Zavrieť</button>
                        <loadingButton class="btn mb-2 ml-3" type="submit" :loading="forgotPassLoading">Resetovať heslo</loadingButton>                  
                    </div>
                    
                </form>
            </div>
        </modal>
    </div>
</template>

<script>
import loadingButton from "@/components/loading-button";
import modal from "@/components/modal";

/**
 * Login page
*/
export default {
    name: "login",
    components: {
        loadingButton,
        modal,
    },
    data() {
        return {
            email: "",
            pass: "",
            loginLoading: false,
            forgotPassModalOpen: false,
            forgotEmail: "",
            forgotPassLoading: false,
        };
    },
    methods: {
        /**
         * Open modal reset password
        */ 
        openForgotPassModal() {
            this.forgotPassModalOpen = true;
        },
        /**
         * Close modal reset password
        */ 
        closeForgotPassModal(e) {
            e.preventDefault();
            this.forgotPassModalOpen = false;
        },
        /**
         * Reset password
        */ 
        async hadleResetPass(e) {
            e.preventDefault();
            this.forgotPassLoading = true;
            const res = await this.$store.dispatch("auth/resetPassword", this.forgotEmail);

            if(res?.state === true) {
                this.$toasted.show(res?.message, { type: "success", position: "top-center", fullWidth: true, duration: 3000 });
                this.forgotEmail = '';
                this.forgotPassModalOpen = false;
            } else {
                this.$toasted.show(res?.message, { type: "error", position: "top-center", fullWidth: true, duration: 3000 });
            }
            this.forgotPassLoading = false;
        },
        /**
         * Login user
        */ 
        async handleLogin(e) {
            e.preventDefault();

            this.loginLoading = true;

            const res = await this.$store.dispatch("auth/login", {
                email: this.email,
                password: this.pass,
            });

            if (res?.state === true) {               
                this.$router.push("/models");
            } else {               
                this.$toasted.show(res?.message, { type: "error", position: "top-center", fullWidth: true, duration: 3000 });
            }

            this.loginLoading = false;
        },
    },
    mounted( ) {
    }
};
</script>

<style lang="scss">
.login-container {
    background: $dark-400;
}
.login-logo {
    width: 140px;
}
</style>
