<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h1>Používatelia</h1>
            </div>
            <div class="col-12 mt-7">
                <button class="btn" @click="openAddUserModal">Pridať nového používateľa</button>               
            </div>

            <div class="col-12 mt-7">
                <userItem v-for="user in users" :key="user.id" :user="user" class="mb-2" @onDelete="getUsers" />
            </div>
        </div>

        <modal @onClose="closeAddUserModal" v-if="addUserModalOpen">
            <div class="p-6 p-sm-8 p-lg-9">
                <addUser @onClose="closeAddUserModal" />
            </div>
        </modal>
    </div>
</template>

<script>
import modal from "@/components/modal";
import userItem from '@/components/user-item'
import addUser from '@/components/add-user'

/**
 * Users page
*/
export default {
    name: "users",
    components: {
        modal,
        userItem,
        addUser
    },
    data() {
        return {
            users: [],
            addUserModalOpen: false,
        };
    },
    methods: {
        /**
         * Load users
        */
        async getUsers() {
            this.users = [];
            const res = await this.ajaxGet(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/auth/user`);            
            if (res?.data) {
                this.users = res.data;
            }
        },
        /**
         * Open modal for adding new user
        */
        openAddUserModal() {
            this.addUserModalOpen = true;
        },
        /**
         * Close modal for adding new user
        */
        closeAddUserModal(reloadData = false) {
            this.addUserModalOpen = false;
            if(reloadData) {
                this.getUsers();
            }
        }
    },
    mounted() {
        this.getUsers();
    },
};
</script>

<style lang="scss"></style>
