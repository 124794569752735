<template>
   <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h1>Modely</h1>
            </div>

            <!-- <div class="col-12 mt-7">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="myModels" v-model="onlyMyModels" @change="getModels">
                    <label class="custom-control-label" for="myModels">Zobraziť iba moje modely</label>
                </div>
            </div> -->

            <div class="col-12 mt-7">
                <modelItem v-for="model in models" :key="model.id" :model="model" class="mb-2" @onDelete="getModels"/>
            </div>
        </div>       
    </div>
</template>

<script>
import modelItem from '@/components/model-item'
/**
 * Models page
*/
export default {
   name: 'models',
   components: {
       modelItem
   },
   data() {
       return {
           models: [],
           //onlyMyModels: false
       }
   },
   methods: {   
        /**
         * Load models
        */  
        async getModels() {
           this.models = [];         
           const res = await this.ajaxGet(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/model/admin`);
            if(res?.data) {
                this.models = res.data;
            }
       },      
   },
   mounted() {
       this.getModels();
   }
}
</script>

<style lang='scss'>

</style>