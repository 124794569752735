<template>
    <div>
        <h3>Pridať kategóriu</h3>

        <form @submit="handleSubmit" class="mt-5">
            <div class="form-group mb-2">
                <label for="title" class="input-label">Názov</label>
                <input type="text" class="form-control custom-input" id="title" v-model="title" />
            </div>
            <!-- <div class="form-group mb-2">
                <label for="desc" class="input-label">Popis</label>
                <textarea class="form-control custom-input" id="desc" v-model="desc" rows="2" />
            </div> -->

            <div>
                <div class="mb-1">Náhľadový obrázok</div>
                <label for="file-input-img" class="model-input-label mt-1">
                    <div class="d-flex align-items-center">
                        <span class="ar-icon icon-plus text-4xl"></span>
                        <div class="ml-2">Vybrať obrázok</div>
                    </div>
                </label>
                <input type="file" class="form-control-file" id="file-input-img" @change="processThumbnail" />

                <div class="img-preview-thumb" v-if="thumbPreview">
                    <img :src="thumbPreview" />
                </div>
            </div>

            <div class="d-flex justify-content-end align-items-center flex-wrap mt-5">
                <button class="btn btn-secondary mb-2" @click="handleClose">Zavrieť</button>
                <loadingButton class="mb-2 ml-3" type="submit" :loading="categorySaving">Uložiť</loadingButton>
            </div>
        </form>
    </div>
</template>

<script>
import loadingButton from "@/components/loading-button";
import { resizeImages, uriToBlob } from "@/utils/image-utils";

/**
 * Add new category form (modal content).   
*/
export default {
    name: "add-category",
    components: {
        loadingButton,
    },
    data() {
        return {
            title: "",
            //desc: "",
            thumbFile: "",
            categorySaving: false,
        };
    },
    computed: {
        thumbPreview() {
            if (this.thumbFile) {
                return URL.createObjectURL(this.thumbFile);
            }
            return;
        },
    },
    methods: {
        resizeImages,
        uriToBlob,
        /**
         * Store a new category     
        */
        async handleSubmit(e) {
            e.preventDefault();

            if(!this.title || !this.thumbFile) {
                this.$toasted.show("Musíte vyplniť všetky polia", {type: "error", position: "top-center", fullWidth: true, duration: 3000})
                return;
            }

            this.categorySaving = true;

            let fd = new FormData();
            fd.append("Name", this.title);
            fd.append("Thumbnail", this.thumbFile);

            const res = await this.ajaxPost(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/category`, fd, {
                "Content-Type": "multipart/form-data",
            });
            if (res.status === 200) {
                this.$emit("onClose", true);
                this.$toasted.show("Kategória bola úspešne pridaná", { type: "success", position: "top-center", fullWidth: true, duration: 3000 });
            } else {
                this.$toasted.show("Nepodarilo sa pridať kategóriu", { type: "error", position: "top-center", fullWidth: true, duration: 3000 });
            }
            this.categorySaving = false;
        },
        /**
         * Process thumbnail (resize)      
        */
        async processThumbnail(e) {
            let input = e.currentTarget;
            this.thumbFile = null;
            let images = await this.resizeImages(input.files);
            if (images?.length > 0) {
                this.thumbFile = images[0];
            }
        },
        /**
         * Emit onClose event (close modal)
        */
        handleClose(e) {
            e.preventDefault();
            this.$emit("onClose");
        },
    },
    mounted() {},
};
</script>

<style lang="scss">
.img-preview-thumb {
    overflow: hidden;
    position: relative;
    background: #373a43;
    width: 240px;
    height: 180px;
    margin-right: 10px;
    margin-bottom: 10px;
    &:hover {
        .img-controls {
            opacity: 1;
        }
    }
    img {
        //height: 100%;
        max-height: 100%;
        max-width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: 0 auto;
        transform: translate(-50%, -50%);
    }
}
</style>
