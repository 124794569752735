<template>
    <div>
        <h3>Pridať používateľa</h3>

        <form @submit="handleSubmit" class="mt-5">
            <div class="form-group mb-2">
                <label for="username" class="input-label">Meno</label>
                <input type="text" class="form-control custom-input" id="username" v-model="username" required />
            </div>
            <div class="form-group mb-2">
                <label for="email" class="input-label">Email</label>
                <input type="email" class="form-control custom-input" id="email" v-model="email" required />
            </div>
            <div class="form-group mb-2">
                <label for="password" class="input-label">Heslo</label>
                <input type="password" class="form-control custom-input" id="password" v-model="password" required />
            </div>

            <div class="d-flex justify-content-end align-items-center flex-wrap mt-5">
                <button class="btn btn-secondary mb-2" @click="handleClose">Zavrieť</button>
                <loadingButton class="mb-2 ml-3" type="submit" :loading="userSaving">Uložiť</loadingButton>
            </div>

        </form>
    </div>
</template>

<script>
import loadingButton from "@/components/loading-button";

/**
 * Add new user form (modal content).   
*/
export default {
    name: "add-user",
    components: {
        loadingButton,
    },
    data() {
        return {
            username: "",
            email: "",
            password: "",
            userSaving: false,
        };
    },     
    methods: {
        /**
         * Store a new user     
        */  
        async handleSubmit(e) {
            e.preventDefault();
            this.userSaving = true;

            let fd = {
                username: this.username,
                email: this.email,
                password: this.password,
            }

            const res = await this.ajaxPost(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/auth/user`, fd);
            console.log(res)
            if(res.status === 200) {
                this.$emit('onClose', true);
                this.$toasted.show("Používateľ bol úspešne pridaný", {type: "success", position: "top-center", fullWidth: true, duration: 3000})
            } else if(res.status === 409) {
                this.$toasted.show("Používateľ s týmto emailom už existuje", {type: "error", position: "top-center", fullWidth: true, duration: 3000})
            } else {
                this.$toasted.show("Nepodarilo sa pridať používateľa", {type: "error", position: "top-center", fullWidth: true, duration: 3000})
            }
            this.userSaving = false;
        },       
        /**
         * Emit close event
        */
        handleClose(e) {
            e.preventDefault();
            this.$emit('onClose')
        }
    },
    mounted() {},
};
</script>

<style lang="scss">
    
</style>
