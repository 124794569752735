<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h1>Kategórie</h1>
            </div>
            <div class="col-12 mt-7">
                <button class="btn" @click="openAddCategoryModal">Pridať novú kategóriu</button>

                <!-- <div class="custom-control custom-checkbox mt-7">
                    <input type="checkbox" class="custom-control-input" id="myCategories" v-model="onlyMyCategories" @change="getCategories" />
                    <label class="custom-control-label" for="myCategories">Zobraziť iba moje kategórie</label>
                </div> -->
            </div>

            <div class="col-12 mt-7">
                <categoryItem v-for="cat in categories" :key="cat.id" :category="cat" class="mb-2" @onDelete="getCategories" />
            </div>
        </div>

        <modal @onClose="closeAddCategoryModal" v-if="addCategoryOpen">
            <div class="p-6 p-sm-8 p-lg-9">
                <addCategory @onClose="closeAddCategoryModal" />
            </div>
        </modal>
    </div>
</template>

<script>
import modal from "@/components/modal";
import addCategory from "@/components/add-category";
import categoryItem from "@/components/category-item";

/**
 * Categories page
*/
export default {
    name: "categories",
    components: {
        modal,
        addCategory,
        categoryItem,
    },
    data() {
        return {
            categories: [],
            addCategoryOpen: false,
            //onlyMyCategories: false,
        };
    },
    methods: {
        /**
         * Load categories
        */
        async getCategories() {
            this.categories = [];          
            const res = await this.ajaxGet(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/category/admin`);
            if (res?.data) {
                this.categories = res.data;
            }
        },
        /**
         * Open modal for adding new category
        */
        openAddCategoryModal() {
            this.addCategoryOpen = true;
        },
        /**
         * Close modal for adding new category
         * @param{bool} reloadData Reload categories if true (if new category was added)
        */
        closeAddCategoryModal(reloadData = false) {
            this.addCategoryOpen = false;
            if (reloadData) {
                this.getCategories();
            }
        },
    },
    mounted() {
        this.getCategories();
    },
};
</script>

<style lang="scss"></style>
