<template>
    <div class="container pt-8">
        <div class="row justify-content-center">
            <div class="col-5">
                <div class="login-container rounded-md p-9">
                    <div class="text-white text-center text-xl font-bold">Resetovanie hesla</div>

                    <form class="mt-5" @submit="handleReset">
                        <div class="form-group mb-2">
                            <label for="password" class="input-label">Heslo</label>
                            <input type="password" class="form-control custom-input" id="password" v-model="password" required />
                        </div>
                        <div class="form-group mb-2">
                            <label for="passwordConfirm" class="input-label">Potvrdenie hesla</label>
                            <input type="password" class="form-control custom-input" id="passwordConfirm" v-model="passwordConfirm" required />
                        </div>

                        <loadingButton class="mt-5 btn-full" type="submit" :loading="passwordLoading">Zmeniť heslo</loadingButton>
                    </form>
                </div>
            </div>
        </div>       
    </div>
</template>

<script>
import loadingButton from "@/components/loading-button";
/**
 * Reset password page
*/ 
export default {
    name: "reset-password",
    components: {
        loadingButton
    },
    data() {
        return {
            password: '',
            passwordConfirm: '',
            passwordLoading: false,
        };
    },
    methods: {
        /**
         * Reset password
        */ 
        async handleReset(e) {
            e.preventDefault();
            if(this.password !== this.passwordConfirm) {
                this.$toasted.show('Heslá sa musia zhodovať', { type: "error", position: "top-center", fullWidth: true, duration: 3000 });
                return;
            }
        
            const res = await this.$store.dispatch("auth/changePassword", {
                password: this.password,
                token: this.$route.query.token || '',
            });

            if(res?.state === true) {
                this.$toasted.show(res?.message, { type: "success", position: "top-center", fullWidth: true, duration: 3000 });
                this.password = '';
                this.passwordConfirm = '';
            } else {
                this.$toasted.show(res?.message, { type: "error", position: "top-center", fullWidth: true, duration: 3000 });
            }


        }
    },
    mounted() {},
};
</script>

<style lang="scss"></style>
