import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

//modules
import Auth from "./modules/auth";

export default new Vuex.Store({
    state: {
        aaa: ''
    },
    getters: {
        getAAA: (state) => {
            return state.aaa;
        },
    },
    mutations: {
        setAAA(state, payload) {
            state.aaa = payload;
        },
    },
    actions: {
        async aaa({ commit, dispatch }) {
            try {
             
            } catch(err) {
                throw new Error(err);
            }
        },
    },
    modules: {
        auth: Auth,
    },
})