<template>
    <div class="modal-wrapper">
        <div class="d-flex flex-column h-100 w-100 justify-content-center align-items-center" @click.self="handleClose">
            <div
                class="modal-window rounded-md position-relative"
                :class="[fullscreen && 'fullscreen', scrollable && 'scrollable', showFixedBar && 'modal-top-spacing']"
            >              
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * Modal   
*/
export default {
    name: "modal",
    components: {},
    props: {
        fullscreen: Boolean,
        scrollable: Boolean,
        closeIcon: Boolean,
        showFixedBar: Boolean,
        title: String,
        iconName: String,
    },
    data() {
        return {};
    },
    computed: {},
    methods: {
        /**
         * Emit close event  
        */
        handleClose() {
            this.$emit("onClose");
        },
    },
    mounted() {},
};
</script>

<style lang="scss">
.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.65);
    z-index: 10000;
    .modal-window {
        background: $dark-400;
        width: 95%;
        max-height: 95%;
        overflow: hidden;
        position: relative;
        max-width: 800px;       
        &.fullscreen {
            min-width: 100%;
            min-height: 100%;
            border-radius: 0 !important;
        }
        &.modal-top-spacing {
            padding-top: 55px;
        }
        &.scrollable {
            overflow-y: auto;
        }
    }    
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity 0.3s;
}
.modal-fade-enter, .modal-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
