<template>
    <div class="rounded py-4 px-4 py-sm-6 d-flex category-item align-items-center">
        <div class="category-thumb mr-4 mr-sm-5">
            <img :src="category.thumbnail" />
        </div>
        <div class="d-flex flex-column flex-sm-row">
            <div class="mr-4">
                <span class="font-bold">Id:</span>
                {{ category.id }}
            </div>
            <div>
                <span class="font-bold">Meno:</span>
                {{ category.name }}
            </div>
        </div>
        <div class="remove-button ml-auto" @click="openDeleteCategoryModal" v-if="category.canEdit">
            <span class="ar-icon icon-close"></span>
        </div>

        <modal @onClose="closeDeleteCategoryModal" v-if="deleteCategoryOpen">
            <div class="p-7">
                <h3>Naozaj chcete odstrániť túto kategóriu?</h3>
                <div class="mt-3">
                    Odstránením tejto kategórie budú odstránené všetky modely patriace do tejto kategórie!
                </div>
                <div class="d-flex justify-content-end align-items-center mt-5">
                    <button class="btn btn-secondary" type="button" @click="closeDeleteCategoryModal">
                        Zrušiť
                    </button>
                    <loadingButton class="btn ml-3" type="button" :loading="categoryDeleting" @buttonClick="deleteCategory">Odstrániť</loadingButton>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import modal from "@/components/modal";
import loadingButton from "@/components/loading-button";
import { mapState } from 'vuex'

/**
 * Category list item
*/
export default {
    name: "category-item",
    props: ["category"],
    components: {
        modal,
        loadingButton
    },
    data() {
        return {
            deleteCategoryOpen: false,
            categoryDeleting: false,
        };
    },
    computed: {
        ...mapState(['auth']),
    },
    methods: {
        /**
         * Open modal to delete category
        */       
        openDeleteCategoryModal() {
            this.deleteCategoryOpen = true;
        },
        /**
         * Close modal to delete category
        */  
        closeDeleteCategoryModal() {
            this.deleteCategoryOpen = false;
        },
        /**
         * Delete category
        */  
        async deleteCategory() {
            this.categoryDeleting = true;
            const res = await this.ajaxDelete(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/category/${this.category?.id}`);
            if (res.status === 200) {
                this.$emit("onDelete");
            } else {
                this.$toasted.show("Nepodarilo sa odstrániť kategóriu", { type: "error", position: "top-center", fullWidth: true, duration: 3000 });
            }
            this.categoryDeleting = false;
        },
    },
    mounted() {},
};
</script>

<style lang="scss">
.category-item {
    background: $dark-300;
    transition: all 0.25s ease;
    &:hover {
        background: $dark-200;
    }
    .category-thumb {
        width: 60px;
        height: 60px;
        position: relative;
        @media screen and (min-width: 768px) {
             width: 120px;
            height: 120px;
        }
        img {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            -o-object-fit: contain;
            object-fit: contain;
        }
    }
}

.remove-button {
    width: 40px;
    height: 40px;
    background: $red-400;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.25s ease;
    cursor: pointer;
    &:hover {
        background: $red-500;
    }
}
</style>
